import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  spinner: {
    display: 'flex',
    margin: '20 auto',
  },
});

const WilcomLoader = (props) => {
  const { classes, theme, loading } = props;
  return (
    <div className={classes.container}>
      <ClipLoader
        className={classes.spinner}
        sizeUnit="px"
        size={100}
        color={theme.palette.primary.main}
        loading={loading}
      />
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(WilcomLoader);
