import { createMuiTheme } from '@material-ui/core/styles';

const createTheme = themeType =>
  createMuiTheme({
    spacing: 4,
    breakpoints: {
      values: {
        xs: 0,
        sm: themeType === 'light' ? 400 : 600, // holy crap is this a hack
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
    palette: {
      type: themeType,
      primary: {
        main: '#3386E2',
      },
      secondary: {
        main: '#EF6E11',
      },
      // error: will use the default color
      background: {
        paper: themeType === 'light' ? '#FFF' : '#3D3D3D',
        default: themeType === 'light' ? '#F3F3F3' : '#454545',
      },
    },
    typography: {
      fontFamily: ['Lato', 'Segoe UI', 'Roboto', 'Open Sans', 'Arial', 'sans-serif'].join(','),
      h4: {
        fontSize: '1.25rem',
        color: themeType === 'light' ? '' : '#E2E2E2',
      },
      h5: {
        fontSize: '1rem',
      },
      h6: {
        fontSize: '0.875rem',
      },
      body1: {
        color: themeType === 'light' ? '' : '#E2E2E2',
      },
      subtitle2: {
        fontSize: '12px',
      },
      button: {
        textTransform: 'none',
      },
      useNextVariants: true,
    },
  });

export default createTheme;
