import React from 'react';

import MarketingBannerContainer from '../container/MarketingBannerContainer';

// there will probably be more in the news page than just the
// marketing banner, heance we have this "page" fragment
const NewsPage = () => (
  <MarketingBannerContainer />
);

export default NewsPage;
