import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { AppBar, Tabs, Tab, Toolbar } from '@material-ui/core';

const styles = () => ({
  toolbar: {
    justifyContent: 'center',
    backgroundColor: '#F3F3F3',
    maxHeight: 48,
  },
});
const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#F3F3F3',
    color: '#000',

    '& > span': {
      height: 2,
      maxWidth: 50,
      width: '100%',
      backgroundColor: '#276A95',
    },
  },
})(props => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    color: '#000',
    fontWeight: '500',
    fontFamily: 'Tahoma',
    fontSize: theme.typography.pxToRem(16),
    marginRight: 14,
    overflow: 'visible',
    '&:focus': {
      opacity: 1,
    },
  },
}))(props => <Tab disableRipple {...props} />);

const Header = props => {
  const {
    location: { pathname },
    match,
    classes,
  } = props;
  // we need to do some route parsing because we're not using NavLink from react router
  // this is not robust and will require some tweaking if there are ever query params
  const segment = pathname.match(/(?<=sidepane\/)[^/]+(?=\/$|$)/i);
  const to = segment ? segment[0] : '';

  if (!to) {
    return null;
  }

  return (
    <AppBar position="static" color="default" elevation={0}>
      <Toolbar variant="dense" className={classes.toolbar}>
        <StyledTabs value={to} fullWidth style={{ textAlign: 'center', minHeight: 50 }}>
          <StyledTab
            label="News"
            value="news"
            component={Link}
            to={`${match.url}/news`}
            disableRipple
            disableTouchRipple
          />
          {/* <StyledTab
            classes={{ root: classes.tab }}
            label="Community"
            value="community"
            component={Link}
            to={`${match.url}/community`}
            disableRipple
            disableTouchRipple
          /> */}
        </StyledTabs>
      </Toolbar>
    </AppBar>
  );
};

export default withRouter(withStyles(styles)(Header));
