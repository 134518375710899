import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import OfflinePage from '../component/OfflinePage';
import axios from 'axios';

const styles = () => ({
  container: {
    display: 'flex',
    overflow: 'auto',
    width: '100%',
    alignContent: 'flex-start',
    justifyContent: 'center',
    height: 'calc(100% - 48px)',
    background: '#F3F3F3',
    paddingTop: 20,
  },
  '@global': {
    'html, body, #root': {
      height: '100%',
    },
  },
});

class SidePaneContentContainer extends Component {
  constructor(props) {
    super(props);
    const { classes} = props;
    this.state = {
      classes,
      isOnline: true,
    }
  }

  componentDidMount() {
    axios.get("https://prod.api.wilcom.live/prod/getgeo")
    .then((response) => {
      this.setState({
        ...this.state,
        isOnline: true,
      })
    })
    .catch((err) => {
      this.setState({
        ...this.state,
        isOnline: false,
      })
    })
  }

  render() {
    return (
      <Grid container className={this.state.classes.container}>
        {!this.state.isOnline && <OfflinePage/>}
        {!!this.state.isOnline && this.props.children}
      </Grid>
    );
  }

}

export default withStyles(styles)(SidePaneContentContainer);
