import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, GridList, GridListTile, GridListTileBar, Button } from '@material-ui/core';
import axios from 'axios';
import WilcomLoader from '../component/WilcomLoader';
import TextTruncate from 'react-text-truncate'; // recommend

const styles = theme => ({
  container: {
    width: '100%',
    maxWidth: '500px',
  },
  gridList: {
    display: 'flex',
    width: '100%',
    alignContent: 'flex-start',
    padding: '10px',
  },
  root: {
    cursor: 'pointer',
  },
  image: {
    filter: 'brightness(100%)',
    transition: 'filter 0.5s',
  },
  tileBarTopRootLarge: {
    display: 'flex',
    alignItems: 'flex-start',
    height: '100%',
    paddingTop: '20px',
    background: 'transparent',
    fontFamily: 'Open Sans, Roboto, Tahoma',
    border: '#D6D6D6 1px solid',
  },
  tileBarTopRootSmall: {
    display: 'flex',
    alignItems: 'flex-start',
    height: theme.typography.pxToRem(210),
    paddingTop: '20px',
    background: 'transparent',
    fontFamily: 'Open Sans, Roboto, Tahoma',
    border: '#D6D6D6 1px solid'
  },
  titleToolTip: { maxWidth: 200, fontSize: 12 },
  tileBarTopTitleLarge: {
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(20),
    fontWeight: '500',
    whiteSpace: 'normal',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    
  },
  tileBarTopTitleSmall: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(18),
    whiteSpace: 'normal',
    paddingBottom: '10px',
    minHeight: theme.typography.pxToRem(28),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    // padding: '15px 0',
    fontWeight: '500',
  },
  tileBarTopSubtitle: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1,
    textOverflow: 'clip',
    overflow: 'visible',
    whiteSpace: 'normal',
    textShadow: '1px 1px 2px rgba(0,0,0,0.3)',
  },
  tileBarBotLarge: {
    display: 'flex',
    alignItems: 'flex-center',
    justifyContent: 'center',
    // height: theme.typography.pxToRem(160),
    background: 'rgba(255,255,255,.52)',
    opacity: 1,
    backdropFilter: 'blur(9px)',
    paddingTop: 40,
    paddingBottom: 65,
    '&:hover': {
      paddingTop: 70,
      paddingBottom: 55,
      '& $catButton': {
        display: 'flex'
      }
    },
    marginLeft: 1,
    marginRight: 1,
    marginBottom: 1,
  },
  tileBarBotSmall: {
    display: 'flex',
    alignItems: 'flex-center',
    justifyContent: 'center',
    // height: theme.typography.pxToRem(100),
    background: 'rgba(255,255,255,.52)',
    opacity: 1,
    backdropFilter: 'blur(9px)',
    paddingTop: 40,
    paddingBottom: 40,
    '&:hover': {
      paddingTop: 70,
      paddingBottom: 40,
      '& $catButton': {
        display: 'flex'
      }
    },
    marginLeft: 1,
    marginRight: 1,
    marginBottom: 1,
  },
  catButton: {
    display: 'none',
    background: '#EFEFEF',
    margin: '15px 0',
    border: '#B2B2B2 solid 1px',
    borderRadius: 3,
    height: 21,
    width: theme.typography.pxToRem(83),
    textAlign: 'left',
    fontSize: theme.typography.pxToRem(12),

    font: 'Regular 12px/14px Tahoma',
    letterSpacing: '0px',
    color: '#000000',
    opacity: 1,
    '&:hover': {
      color: '#FFFFFF',
      background: '#276A95',
      border: 'none',
    },
  },
});

class MarketingBannerContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      banners: [],
      isLoading: true,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    let env = process.env.REACT_APP_ENV? process.env.REACT_APP_ENV: "prod";
    console.log({env});
    axios
      .get('https://hatch3'+ env +'.s3.ap-southeast-2.amazonaws.com/homenews/News.txt', {
        headers: {
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache', // ensure news.txt is not cached by browser
          Pragma: 'no-cache',
        },
      })
      .then(res => {
        console.log({ res });
        this.setState({
          banners: res.data,
          isLoading: false,
        });
      })
      .catch(() => {
        this.setState({
          banners: [],
          isLoading: false,
        });
      });
  }

  render() {
    const { classes } = this.props;
    const { banners, isLoading } = this.state;

    if (isLoading) {
      return <WilcomLoader loading={isLoading} />;
    }

    return (
      <Grid item className={classes.container}>
        <GridList cellHeight={300} spacing={10} cols={2} className={classes.gridList}>
          {banners.map((tile, idx) => {
            const isFeatured = true;

            const MoreLinkURL = new URL(tile.MoreLink);
            const sourceVersion = window.location.hostname.replace('.wilcomhs.com', '');

            MoreLinkURL.searchParams.append('utm_campaign', 'software');
            MoreLinkURL.searchParams.append('utm_medium', 'tab_news');
            MoreLinkURL.searchParams.append('utm_source', sourceVersion);

            return (
              <GridListTile
                key={MoreLinkURL.href}
                className={classes.root}
                cols={isFeatured ? 2 : 1}
                // rows={isFeatured ? 2 : 1}
                onClick={() => {
                  window.open(MoreLinkURL, '_blank');
                }}
              >
                <img className={classes.image} src={tile.BackgroundImage} alt={tile.Title} />
                <GridListTileBar
                  subtitle=""
                  titlePosition="top"
                  classes={{
                    root: isFeatured ? classes.tileBarTopRootLarge : classes.tileBarTopRootSmall,
                    title: isFeatured ? classes.tileBarTopTitleLarge : classes.tileBarTopTitleSmall,
                    subtitle: classes.tileBarTopSubtitle,
                  }}
                />
                  <GridListTileBar
                    title={
                      <div>
                        <div style={{ color: "#000000" }}>
                          <TextTruncate
                            id="top-title"
                            line={2}
                            element="div"
                            truncateText=" …"
                            text={tile.Title}
                          />
                        </div>
                        <div>
                          <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            className={classes.catButton}
                          >
                            {tile.MoreButtonLabel || 'Learn More'}
                          </Button>
                        </div>
                      </div>
                    }
                    titlePosition="bottom"
                    className={isFeatured ? classes.tileBarBotLarge : classes.tileBarBotSmall}
                    classes={{
                      title: isFeatured
                        ? classes.tileBarTopTitleLarge
                        : classes.tileBarTopTitleSmall,
                      subtitle: classes.tileBarTopSubtitle,
                    }}
                  />
              </GridListTile>
            );
          })}
        </GridList>
      </Grid>
    );
  }
}

export default withStyles(styles)(MarketingBannerContainer);
