import React from "react";
import { Button, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const styles = () => ({
  noInternet: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
    maxWidth: "500px",
    rowGap: "20px",
    padding: "35px",
  },
  refreshButton: {
    color: "#FFFFFF",
    background: "#276A95",
    padding: "0px 50px",
    height: "5px",
    "&:hover": {
      color: "#FFFFFF",
      background: "#276A95",
    },
  },
});

const OfflinePage = (props) => {
  const { classes } = props;

  const refreshPage = () => {
    window.location.reload();
  }
  
  return (
    <div className={classes.noInternet}>
      <img src="/no_internet_icon.svg" alt="No internet icon" />
      <Typography style={{ fontWeight: "700", fontSize: "18px" }}>
        Oops! No Internet connection
      </Typography>
      {/* <Typography>Error code: </Typography> */}
      <Typography align="center" style={{ fontWeight: "600" }}>
        Please connect and refresh to continue enjoying all the benefits of
        Hatch Embroidery
      </Typography>
      <Typography style={{ color: "grey" }}>
        <ul>
          <li>Quick access Hatch Academy</li>
          <li>Share and learn tips and tricks from Hatch Community</li>
          <li>
            Stay connected with the latest Hatch blog posts, news and offers
          </li>
          <li>Keep your product up to date</li>
        </ul>
      </Typography>
      <Button className={classes.refreshButton} onClick={refreshPage}>Refresh</Button>
    </div>
  );
};

export default withStyles(styles)(OfflinePage);
