import React from 'react';
import { Switch, Route } from 'react-router-dom';

import SidePaneRoutes from './routes/SidePaneRoutes';
// import TutorialRoutes from "./routes/TutorialRoutes";

const App = ({ match }) => (
  <Switch>
    {/*   <Route path={`${match.path}/tutorial`} component={TutorialRoutes} /> */}
    <Route path={`${match.path}/sidepane`} component={SidePaneRoutes} />
  </Switch>
);

export default App;
