import React from 'react';
import { Switch, Route, Redirect, BrowserRouter as Router } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';

import ESTheme from './themes/ESTheme';
import App from './App';

const cl = console.log;
const cw = console.warn;
const ce = console.error;
window.__RE_LogEnable = function() {
  console.log = cl;
  console.warn = cw;
  console.error = ce;
};
if(process.env.REACT_APP_ENV === "prod") {
  console.log = () => {};
  console.warn = () => {};
} else {
  console.log({ LOG_REACT_APP_ENV: process.env.REACT_APP_ENV });
}

// by default we use the 'light' theme
const theme = ESTheme('light');
console.log({ theme });
// load environment variables
require('dotenv').config();

const supportedLang = [
  'en', // English
  'nl', // Dutch
  'fr', // French
  'de', // German
  'it', // Italian
  'ja', // Japanese
  'ko', // Korean
  'pt', // Portugese
  'ru', // Russian
  'es', // Spanish
  'sv', // Swedish
];

const AppWrapper = function() {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Switch>
          <Redirect exact from="/" to="/en/sidepane" />
          {/* should only allow supported languages in the future */}
          <Route path={`/:lang(${supportedLang.join('|')})?`} component={App} />
        </Switch>
      </Router>
    </MuiThemeProvider>
  );
};

export default AppWrapper;
